<template>
  <div
    class="sticky top-0 z-50 box-border flex h-16 flex-shrink-0 items-center border-b border-main-150 bg-main-dark xs:h-[94px]"
  >
    <LazyLayoutsHeaderAuth v-if="showHeaderAuth" />
    <LazyLayoutsHeaderNoAuth v-else />
  </div>

  <!-- pending deposit notification -->
  <v-dialog
    v-model="pending.pendingDepositPopup"
    width="auto"
    :persistent="true"
    :no-click-animation="true"
  >
    <v-card
      class="mx-[10px] w-[calc(100vw-68px)] max-w-[560px] !rounded-[16px] !bg-main-dark !px-[16px] !pb-[24px] !pt-4 text-sm lg:!px-8 lg:!pb-10 lg:text-lg"
    >
      <div class="relative left-0 flex justify-end lg:left-4">
        <button
          @click="pending.pendingDepositPopup = false"
          type="button"
          class="rounded-full hover:bg-main-125"
        >
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.0884 15.0877L24.9123 24.9116"
              stroke="white"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M15.0877 24.9116L24.9116 15.0877"
              stroke="white"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>

      <div class="mb-8 flex flex-col items-center gap-4">
        <div>
          <img src="/images/icon/pending-deposit.png" alt="" />
        </div>
        <p
          v-if="pending.pendingDepositTitle"
          class="w-full truncate text-center font-extrabold text-white"
        >
          {{ pending.pendingDepositTitle }}
        </p>
        <p class="line-clamp-[7] w-full text-center font-extrabold text-white">
          {{ pending.pendingDepositMessage }}
        </p>
      </div>

      <div class="flex items-center justify-center">
        <button
          @click="pending.pendingDepositPopup = false"
          type="button"
          class="h-[56px] !rounded-lg bg-main-pink px-8 font-extrabold text-white"
        >
          {{ $t("common.btn.close") }}
        </button>
      </div>
    </v-card>
  </v-dialog>
  <ClientOnly>
    <Pusher v-model="pending" />
  </ClientOnly>
</template>

<script setup lang="ts">
import { useState } from "#app";
import Pusher from "~/components/layouts/Pusher.client.vue";

const tokenCookie = useToken();
const showHeaderAuth = tokenCookie.value;

const pending = ref({
  pendingDepositPopup: false,
  pendingDepositMessage: "",
  pendingDepositTitle: "",
});

const hideHeaderFooter = useState("hideHeaderFooter");
</script>
