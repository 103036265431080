<template>
  <div
    class="group-tooltip group-custom !pointer-events-auto !cursor-default"
    :class="{
      disabled: item.disabled,
      challenge: item.url === ROUTES_NAME.CHALLENGES,
    }"
  >
    <NuxtLink
      @click="
        () => {
          if (typeof item.callback === 'function') item.callback();
          scrollToTop();
        }
      "
      :to="item.disabled ? '' : item.url"
      class="flex items-center justify-center gap-3 !rounded-xl p-3 max-[1279.98px]:w-full"
      :class="[
        railNav ? 'w-full' : 'h-[45px]',
        { 'icon-main-pink-active': route.path.includes(item.url) },
      ]"
      active-class="[&_.menu-title]:text-main-pink"
    >
      <LazyNavbarSvg v-if="!item.image" :item="item" />
      <div class="h-5 w-5" v-else>
        <img
          loading="lazy"
          class="icon-main-pink-hover h-full w-full object-contain"
          :src="item.image"
          alt=""
        />
      </div>

      <div
        class="truncate max-[1279.98px]:flex max-[1279.98px]:flex-1 max-[1279.98px]:items-center max-[1279.98px]:justify-between max-[1279.98px]:pl-2"
        :class="
          !railNav ? 'hidden' : 'flex flex-1 items-center justify-between'
        "
      >
        <div
          class="menu-title flex-1 truncate text-sm font-semibold leading-[150%]"
          :class="[
            route.path.includes(item.url) ? 'text-main-pink' : 'text-white',
            {
              '!text-disable-100': item.disabled,
            },
          ]"
        >
          {{ $t(item.name) }}
        </div>
      </div>
    </NuxtLink>

    <!-- tooltip -->
    <v-tooltip
      activator="parent"
      class="custom-tooltip"
      :class="{
        '!hidden': railNav,
      }"
    >
      <NuxtLink
        @click="
          () => {
            if (typeof item.callback === 'function') item.callback();
            scrollToTop();
          }
        "
        :to="item.disabled ? '' : item.url"
        class="shadow-main-light hidden h-[45px] w-[160px] items-center justify-center !rounded-xl bg-white p-3 duration-200 xl:flex"
      >
        <div class="flex flex-1 items-center justify-between pl-2">
          <div
            class="menu-title flex-1 text-sm font-semibold leading-[150%] text-main-dark"
          >
            {{ $t(item.name) }}
          </div>
        </div>
      </NuxtLink>
    </v-tooltip>
  </div>
</template>
<script setup lang="ts">
import { ROUTES_NAME } from "~/config/router";
import { INavBarItem } from "~/types/schema";

type Props = {
  item: INavBarItem;
  railNav: boolean;
};
defineProps<Props>();
const route = useRoute();
</script>
