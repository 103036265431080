import { BONUS_TAB_TYPE, CLAIM_TYPE } from "~/config/enum";

export const getBonusByType = (type: string, page?: number) => {
  let params = "";
  let pageNumber: string | undefined = "";
  switch (type) {
    case BONUS_TAB_TYPE.AVAILABLE:
      params = "available-bonuses";
      break;
    case BONUS_TAB_TYPE.ACTIVE:
      params = "active-bonuses";
      break;
    case BONUS_TAB_TYPE.HISTORY:
      params = "bonus-history";
      if (page) {
        pageNumber = `?page=${page}`;
      }
      break;
  }
  const { $api } = useNuxtApp();
  return $api.request({
    url: `/api/bonuses/${params}${pageNumber}`,
    method: "get",
  });
};

export const readBonus = () => {
  const { $api } = useNuxtApp();
  return $api.request({
    url: `/api/bonuses/read`,
    method: "post",
  });
};

export const claimBonus = (
  id: number,
  claim_status: CLAIM_TYPE,
  entity_wallet_code?: string
) => {
  let entityWalletCode = "";
  if (entity_wallet_code) {
    entityWalletCode = `&entity_wallet_code=${entity_wallet_code}`;
  }
  const { $api } = useNuxtApp();
  return $api.request({
    url: `/api/bonuses/${id}/claim?claim_status=${claim_status}${entityWalletCode}`,
    method: "patch",
  });
};
