<template>
  <v-dialog
    v-model="navbarSearch"
    :persistent="true"
    :width="'80%'"
    :max-width="'1200px'"
    :no-click-animation="true"
    :onClick:outside="
      () =>
        onClickOutsideDialog({
          callback: () => (navbarSearch = false),
        })
    "
  >
    <LazyDialogNavbarSearch v-if="navbarSearch" @close="navbarSearch = false" />
  </v-dialog>
</template>

<script lang="ts" setup>
const navbarSearch = defineModel<boolean>();
</script>
