<template>
  <div />
</template>
<script setup lang="ts">
import { storeToRefs } from "pinia";
import CustomNotify from "~/components/CustomNotify.vue";
import { ALERT_TYPE, BONUS_TAB_TYPE } from "~/config/enum";
import { useAuthStore } from "~/stores/authStore";
import { useBonusStore } from "~/stores/bonusStore";
import { useSystemStore } from "~/stores/systemStore";
import { useWalletStore } from "~/stores/walletStore";
import { bonusData } from "~/types/schema";

type Model = {
  pendingDepositPopup: boolean;
  pendingDepositMessage: string;
  pendingDepositTitle: string;
};
const modelValue = defineModel<Model>({
  default: {
    pendingDepositPopup: false,
    pendingDepositMessage: "",
    pendingDepositTitle: "",
  },
});
const authStore = useAuthStore();
const bonusStore = useBonusStore();
const walletStore = useWalletStore();
const { getBonusListDataByType, handleReadBonus } = bonusStore;
const { allowFetchAvailableBonus } = storeToRefs(bonusStore);
const { getUser } = storeToRefs(authStore);
const { updateLevelInformation } = authStore;
const { setSelectedWallet, updateWalletBalance } = walletStore;
const { getSelectedWallet } = storeToRefs(walletStore);
const useSystem = useSystemStore();
const { changeWalletTransactionDialog } = useSystem;
const { bonusDialog } = storeToRefs(useSystem);
const channel = ref("");

const { $toast } = useNuxtApp() || {};

const lang = useCurrentLanguage();
const config = useRuntimeConfig();

function initializeEchoConnection() {
  if ((getUser.value as any)?.id) {
    // if disconnect or lost internet connection then try to reconnect
    window?.Echo?.connector?.pusher?.connection?.bind("disconnected", () => {
      window?.Echo?.connect();
    });
    channel.value = `appalert.${(getUser.value as any).id}`;
    window?.Echo?.private(channel.value)?.on(
      "Illuminate\\Notifications\\Events\\BroadcastNotificationCreated",
      (response: any) => {
        if (response?.type === ALERT_TYPE.PLAYER_PENDING_DEPOSIT) {
          changeWalletTransactionDialog(false);
          modelValue.value.pendingDepositMessage = response?.text;
          modelValue.value.pendingDepositTitle = response?.title;

          setTimeout(() => {
            modelValue.value.pendingDepositPopup = true;
          }, 500);
        } else {
          if (response?.type === ALERT_TYPE.PLAYER_DEPOSIT) {
            loadTrackConvertFirstDeposit(response);
          }

          showToastNotification(response?.title, response?.text);
        }
      }
    );

    window?.Echo?.private(channel.value)?.on(
      "AppAlertEvent",
      async (response: any) => {
        const currentSelectedWalletCode =
          getSelectedWallet?.value?.entity_wallet_code;
        if (response?.type === ALERT_TYPE.ALL_WALLET_BALANCES) {
          const value = response?.value;
          const values = Object.values(value);
          const selectedWallet = values.find(
            (item: any) => item.is_selected
          ) as any;
          if (
            selectedWallet?.entity_wallet_code === currentSelectedWalletCode
          ) {
            setSelectedWallet(selectedWallet);
          }
        }
        if (response?.type === ALERT_TYPE.WALLET_BALANCE) {
          const saveData = {
            ...response?.value,
            coin_image: response?.value?.active_entity_wallet?.coin_image,
          };
          // update selected wallet balance after play, also that wallet shown in the wallet list
          updateWalletBalance(saveData);
        }
        if (response?.type === ALERT_TYPE.LEVEL_PROGRESS) {
          updateLevelInformation(response?.value);
        }
        if (
          response?.type === ALERT_TYPE.UPDATE_AVAILABLE_BONUS &&
          allowFetchAvailableBonus.value
        ) {
          const listData = await getBonusListDataByType(
            BONUS_TAB_TYPE.AVAILABLE
          );
          if (bonusDialog.value) {
            if (listData?.some((item: bonusData) => !item.read_at)) {
              handleReadBonus();
            } else {
              bonusStore.loading = false;
            }
          }
        }
      }
    );

    window?.Echo?.channel("public_alert")?.on(
      "PublicAlertEvent",
      async (response: any) => {
        if (response?.type === ALERT_TYPE.UPDATE_RATE) {
          walletStore.listCurrencies.rate = response.value;
        }
        if (
          response?.type === ALERT_TYPE.NEW_NOTIFICATION &&
          (getUser.value as any)?.id
        ) {
          const title =
            response?.value?.translations?.[lang.value]?.title ??
            response?.value?.title;
          const text =
            response?.value?.translations?.[lang.value]?.body ??
            response?.value?.body;

          showToastNotification(title, text);
        }
      }
    );
  }
}

watch(
  getUser,
  () => {
    initializeEchoConnection();
  },
  {
    immediate: true,
  }
);

const showToastNotification = (title: string, message: string) => {
  $toast.info(
    ({ closeToast, toastProps }: any) =>
      h(CustomNotify, {
        closeToast,
        toastProps,
        title,
        message,
      }),
    {
      bodyClassName: "custom-toast toast-content",
    }
  );
};

const loadTrackConvertFirstDeposit = (response: any) => {
  if (!isClient()) return;
  if (response?.value?.deposit_level === 1) {
    const script = document.createElement("script");
    script.setAttribute(
      "src",
      `https://bca-protocol.firebaseapp.com/conversion-track.js?tagID=${config.public.CONVERSION_TRACK_TAG_ID}&apiKey=${config.public.CONVERSION_TRACK_API_KEY}`
    );
    document.head.appendChild(script);
  }
};

onUnmounted(() => {
  // leave channel after logut or change between layout
  window?.Echo?.leave(channel.value);
});
</script>
