<template>
  <div
    id="auth-layout"
    style="display: flex; position: relative; overflow: hidden; flex: 1"
  >
    <Navbar2 />
    <div class="relative flex-1 overflow-auto scroll-smooth" id="main">
      <DelayHydration>
        <Header />
      </DelayHydration>
      <div class="flex-grow-1 flex min-h-[600px] flex-col">
        <slot />
        <ClientOnly>
          <LazyLobbyTransactionSkeleton v-if="showLobbyTransaction" />
        </ClientOnly>
      </div>
      <DelayHydration>
        <LazyLayoutsFooter class="!pb-[6rem]" />
        <StickyNav class="block xl:hidden" />
        <StoreDialog />
      </DelayHydration>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useRoute } from "#app";
import Header from "~/components/layouts/Header.vue";
import Navbar2 from "~/components/layouts/Navbar2.vue";
import { useSystemStore } from "~/stores/systemStore";

const StoreDialog = defineAsyncComponent(
  () => import("~/components/dialog/StoreDialog.vue")
);
const route = useRoute();

const showLobbyTransaction = computed(() => route.meta.showLobbyTransaction);

const { changeListWalletDialog } = useSystemStore();

onBeforeUpdate(() => {
  changeListWalletDialog(false);
});
</script>
