<template>
  <div class="relative">
    <CommonDropdown
      disableSearch
      :listData="listLangs"
      v-model="open"
      :no-data-message="$t('common.message.no_lang_found')"
      :wrap-class="`h-[40px] ${wrapClass}`"
    >
      <template #selected>
        <div class="flex items-center gap-2 truncate">
          <svg
            class="flex-shrink-0"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.99967 15.1666C11.6816 15.1666 14.6663 12.1819 14.6663 8.49998C14.6663 4.81808 11.6816 1.83331 7.99967 1.83331C4.31778 1.83331 1.33301 4.81808 1.33301 8.49998C1.33301 12.1819 4.31778 15.1666 7.99967 15.1666Z"
              stroke="white"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M5.33275 2.5H5.99941C4.69941 6.39333 4.69941 10.6067 5.99941 14.5H5.33275"
              stroke="white"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10 2.5C11.3 6.39333 11.3 10.6067 10 14.5"
              stroke="white"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M2 11.1667V10.5C5.89333 11.8 10.1067 11.8 14 10.5V11.1667"
              stroke="white"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M2 6.49996C5.89333 5.19996 10.1067 5.19996 14 6.49996"
              stroke="white"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <div class="truncate text-sm font-medium text-white">
            {{ getSelectedLangText }}
          </div>
        </div>
      </template>
      <template #list-dropdown-item>
        <div
          v-for="(lang, index) in listLangs"
          :key="index"
          class="group cursor-pointer p-3 hover:bg-main-125"
          :class="{
            'bg-main-125': currentLang === lang?.value,
            'border-b !border-main-125': index !== listLangs.length - 1,
          }"
          @click="changeSelectedLang(lang.value)"
        >
          <div class="flex items-center justify-between">
            <p
              class="truncate text-sm text-white group-hover:!text-main-pink"
              :class="{
                '!text-main-pink': currentLang === lang?.value,
              }"
            >
              {{ lang.title }}
            </p>
            <div class="h-6 w-6 flex-shrink-0">
              <svg
                v-if="currentLang === lang?.value"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 22.5C17.5228 22.5 22 18.0228 22 12.5C22 6.97715 17.5228 2.5 12 2.5C6.47715 2.5 2 6.97715 2 12.5C2 18.0228 6.47715 22.5 12 22.5Z"
                  fill="#73DD87"
                />
                <path
                  d="M10.5799 16.08C10.3799 16.08 10.1899 16 10.0499 15.86L7.21994 13.03C6.92994 12.74 6.92994 12.26 7.21994 11.97C7.50994 11.68 7.98994 11.68 8.27994 11.97L10.5799 14.27L15.7199 9.13001C16.0099 8.84001 16.4899 8.84001 16.7799 9.13001C17.0699 9.42001 17.0699 9.90001 16.7799 10.19L11.1099 15.86C10.9699 16 10.7799 16.08 10.5799 16.08Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
        </div>
      </template>
    </CommonDropdown>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { listLangs } from "~/config/constant";
import { useAuthStore } from "~/stores/authStore";
import { useLanguageStore } from "~/stores/languageStore";

defineProps<{ wrapClass?: string; contentClass?: string }>();

const { setLocale } = useI18n();

const currentLang = useCurrentLanguage();
const getSelectedLangText = computed(() => {
  const selectedLang = listLangs.value.find(
    (item) => item.value === currentLang.value
  );
  if (selectedLang) return selectedLang.title;
  return listLangs.value[0].title;
});

const { open } = storeToRefs(useLanguageStore());
const token = useCustomCookie("token");
const authStore = useAuthStore();
const { updateLanguage } = authStore;

const changeSelectedLang = async (lang: string) => {
  if (lang !== currentLang.value) {
    open.value = false;
    currentLang.value = lang;
    setLocale(lang as any);
    if (token.value) {
      await updateLanguage(lang);
    }
    window.location.reload();
  }
};

</script>

<style scoped></style>
